import VSwatches  from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
export default {
    name: 'color-picker',
    components: {VSwatches },
    props: ['value','position', 'positionX'],
    data() {
        return {
            color: null,
            swatches: [
                ['#F64272', '#F6648B', '#F493A7', '#F891A6', '#FFCCD5'],
                ['#8b5aff', '#a27bff', '#b99cff', '#d0bdff', '#e8deff'],
                ['#51e5db', '#74ebe3', '#96f0ea', '#b9f5f1', '#dcfaf8'],
                ['#ffa51a', '#ffb748', '#ffc976', '#ffdba3', '#ffedd1']
            ]
        }
    },
    computed: {},
    mounted() {

    },
    methods: {
        onChange() {
            this.$emit('input', this.color);
        }
    },
    beforeMount() {
      this.color = this.value;
    }
}


